import Velocity from 'velocity-animate';

window.addEventListener(
  'DOMContentLoaded',
  () => {
    gnavFunctions.constructor();
    noticeFunctions.constructor();
    accordionFunctions();
  },
  false
);

export const gnavFunctions = {
  constructor: () => {
    gnavFunctions.gnavOpen();
  },
  gnavOpen: () => {
    const $gnav: HTMLElement = document.querySelector('.gnav');
    const $gnavItems: HTMLElement = document.querySelector('.gnav__items');
    const $gnavTrigger: HTMLElement = document.querySelector('.js-menu-open');
    let isAnimation = false;

    $gnavTrigger.addEventListener(
      'click',
      () => {
        if (!isAnimation) {
          isAnimation = true;
          $gnavTrigger.classList.toggle('active');

          if ($gnav.classList.contains('active')) {
            Velocity(
              $gnav,
              { opacity: 0 },
              {
                duration: 400,
                easing: 'easeInOutCirc',
              }
            );
            Velocity(
              $gnavItems,
              { paddingLeft: 25, opacity: 0 },
              {
                duration: 650,
                easing: 'easeInOutCirc',
                complete: () => {
                  $gnav.classList.toggle('active');
                  isAnimation = false;
                },
              }
            );
          } else {
            Velocity(
              $gnav,
              { opacity: 1 },
              {
                duration: 400,
                easing: 'easeInOutCirc',
                complete: () => {},
              }
            );
            Velocity(
              $gnavItems,
              { opacity: 1, paddingLeft: 0 },
              {
                delay: 100,
                duration: 1200,
                easing: 'easeInOutCirc',
                complete: () => {
                  $gnav.classList.toggle('active');
                  isAnimation = false;
                },
              }
            );
          }
        }
      },
      false
    );
  },
};

export const noticeFunctions = {
  constructor: () => {
    noticeFunctions.close();
  },
  close: () => {
    const $notice: HTMLElement = document.querySelector('.notice');
    const $noticeCloseBtn: HTMLElement = document.querySelector('.notice__close-btn');
    if ($noticeCloseBtn) {
      $noticeCloseBtn.addEventListener(
        'click',
        () => {
          Velocity(
            $notice,
            { opacity: 0 },
            {
              duration: 400,
              easing: 'easeInOutCirc',
              complete: () => {
                $notice.style.display = 'none';
              },
            }
          );
        },
        false
      );
    }
  },
};

const accordionFunctions = () => {
  const $targets: NodeListOf<HTMLElement> = document.querySelectorAll('.js-accordion');
  $targets.forEach(($target: HTMLElement, i: number) => {
    const $trigger: HTMLElement = $target.querySelector('.js-accordion-trriger');
    const $body: HTMLElement = $target.querySelector('.js-accordion-body');
    const height = $body.querySelector('*').clientHeight;

    $trigger.addEventListener(
      'click',
      () => {
        if ($target.classList.contains('js-accordion-open')) {
          $body.style.height = '0px';
        } else {
          $body.style.height = `${height}px`;
        }

        $target.classList.toggle('js-accordion-open');
      },
      false
    );
  });
};
